#print-logo {
  display: none;
}

@media print {
  #badge-items-left {
    display: none;
    visibility: hidden;
  }
  button {
    display: none;
    visibility: hidden;
  }
  #appointment-slots-left {
    display: none;
    visibility: hidden;
  }
  #print-logo {
    display: block;
  }
}